<template>
  <div class="account">
    <div class="cover"></div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-6 align-self-center">
          <h1 class="info">Reise, so wie du bist.</h1>
        </div>
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-2">
          <div class="card">
            <h3 class="card-title">Passwort zurücksetzen</h3>

            <div class="form-group text-center">
              <span class="text">
                Um Ihr Passwort zurückzusetzen geben Sie bitte Ihre E-Mail Adresse ein. Sie erhalten anschließend eine E-Mail mit einem Link mit dem Sie Ihr Passwort zurückzusetzen können.
              </span>
            </div>
            <form action="">
              <div class="register-container">
                <div class="form-group">
                  <span class="material-icons light left">person</span>
                  <input type="text" class="form-control icon-left lg" @blur="validateEmailInput(), checkIfExists()" v-model="credentials.email" placeholder="E-Mail"/>
                </div>
                <span class="validation-error" v-show="showEmailExistsError">Es gibt keinen Account mit dieser E-Mail Adresse.</span>
                <span class="validation-error" v-show="showEmailError">Geben Sie Ihre E-Mail Adresse an.</span>
              </div>

              <div class="form-group">
                <button type="submit" @click="handleApplyForPassword" style="width: 100%;" class="btn btn-fill btn-focus-design" title="">Passwort zurücksetzen</button>
              </div>

              <router-link class="btn btn-link" to="/login">Zurück zum Login?</router-link>
              <p class="mt-5">Keine Mail bekommen? Überprüfe deinen Spam Ordner.</p>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { validEmail } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { applyResetPassword, csrf, emailExists } from '@/api/auth';

  export default {
    name: 'password-apply',
    data() {
      return {
       credentials: {
        email: "",
        appId: this.$appId,
        appUrl: window.location.origin,
      },
      showEmailExistsError: false,
      showEmailError: false,
      loader: false,
    };
  },
  methods: {
    showLoader,
    hideLoader,
    validEmail,
    validateEmailInput(){
      if(!validEmail(this.credentials.email)){
        this.showEmailError=true;
        return false;
      }
      else{
        this.showEmailError=false;
        return true;
      }
    },
    checkIfExists(){
      var theMail = {email: this.credentials.email};
      emailExists(theMail).then(response => {
        if(response.status == "204"){
          /*no user*/
          this.showEmailExistsError = true;
        }
        else if(response.status == "200"){
          /*a user exists*/
          this.showEmailExistsError = false;
        }
      });
    },
    handleApplyForPassword(e) {
      e.preventDefault();
      if(this.validateEmailInput() && !this.showEmailExistsError){

        this.loader = this.showLoader(this.loader);

        csrf().then(() => {
          applyResetPassword(this.credentials).then(() => {

            this.$notify({
              duration: 3500,
              title: 'Passwort zurückzusetzen erfolgreich!',
              text: "Bitte überprüfen Sie Ihre E-Mails."
            });

            this.$router.push({name:'Home'});
            this.loading = false;
          })
          .catch( error => {

            var message = error.response.data;
            this.$notify({
              duration: 4500,
              type: 'error',
              title: 'Fehler bei dem zurückzusetzen des Passworts!',
              text: message
            });

          }).finally(()=>{
            this.loader = this.hideLoader(this.loader);
          });
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .validation-error{
    color:#E5321B;
    font-size: 13px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .text-center {
    margin-bottom: 1rem;
  }

</style>
